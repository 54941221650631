import { ref } from '@vue/composition-api'
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import actionRepository from '@/repository/actionRepository'
import ability from '@/libs/acl/ability'

export default function actionView() {
  // const toast = useToast()

  const blankData = {
    xmlId: null,
    active: 1,
    activeTo: null,
    activeFrom: null,
    sort: 500,
    title: null,
    alias: null,
    description: null,
    type: null,
    step: null,
    profit: null,
    colorId: null,
    svgSrcId: null,
    createdId: null,
    updatedId: null,
  }

  const elementData = ref({})
  const errorData = ref(null)

  const resetBlankData = () => {
    elementData.value = JSON.parse(JSON.stringify(blankData))
  }

  const getElement = id => new Promise(resolve => {
    if (!ability.can('read', 'Action') && !ability.can('read', 'ActionManage')) {
      errorData.value = 'Недостаточно прав для чтения'
      return
    }
    actionRepository.getElement(id)
      .then(res => {
        if (!res) {
          throw new Error('Акции с текущем идентификатором не найдено')
        }
        elementData.value = {
          ...blankData,
          ...res,
          createdUser: !res.createdUser ? '' : `${res.createdUser.firstName || ''} ${res.createdUser.lastName || ''}`,
          updatedUser: !res.updatedUser ? '' : `${res.updatedUser.firstName || ''} ${res.updatedUser.lastName || ''}`,
        }
        resolve()
      })
      .catch(error => {
        elementData.value = null
        errorData.value = error ? `${error}`.replace('Error: ', '') : ''
      })
  })
  return {
    elementData,
    errorData,

    getElement,
    resetBlankData,
  }
}
