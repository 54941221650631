<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />
                <b-button
                  variant="primary"
                  :to="{ name: 'content-action-view', params: { id: 'new' } }"
                  :disabled="!ability.can('update', 'ActionManage')"
                >
                  <span class="text-nowrap">Создать акцию</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refActionProductTable"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(company)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                rounded
                size="32"
                variant="light-company"
              >
                <b-img
                  :src="data.item.avatarImg"
                  alt="avatar img"
                /></b-avatar>
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.title }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ data.item.subtitle }}
                </div>
              </div>
            </div>
          </template>

          <template #cell(updatedAt)="data">
            {{ formatDate(data.value) }}
          </template>

          <template #cell(active)="data">
            <feather-icon
              v-if="data.value == 1"
              class="text-success"
              icon="EyeIcon"
              size="16"
            />
            <feather-icon
              v-else
              class="text-danger"
              icon="EyeOffIcon"
              size="16"
            />
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item :to="{ name: 'content-action-view', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span
                  v-if="ability.can('update', 'ActionManage')"
                  class="align-middle ml-50"
                >
                  Изменить
                </span>
                <span
                  v-else
                  class="align-middle ml-50"
                >
                  Просмотр
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="ability.can('update', 'ActionManage')"
                @click="activeContentElement(data.item.id, data.item.active)"
              >
                <feather-icon
                  v-if="data.item.active != 1"
                  icon="EyeIcon"
                  size="16"
                />
                <feather-icon
                  v-else
                  icon="EyeOffIcon"
                  size="16"
                />
                <span
                  v-if=" data.item.active === 1"
                  class="align-middle ml-50"
                >Деактивировать</span>
                <span
                  v-else
                  class="align-middle ml-50"
                >Aктивировать</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="ability.can('update', 'ActionManage')"
                @click="deleteContentElemrnt(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BButton, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { formatDate } from '@/libs/helper'
import actionProductList from './actionProductList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['actionId'],
  setup(props) {
    const tableColumns = [
      { key: 'actions', label: '', sortable: false },
      { key: 'code', label: 'Код 1С', sortable: true },
      { key: 'active', label: 'Активность', sortable: true },
      { key: 'sort', label: 'Сортировка', sortable: true },
      { key: 'brand', label: 'Бренд', sortable: true },
      { key: 'articul', label: 'Артикул', sortable: true },
      { key: 'name', label: 'Наименование', sortable: true },
      { key: 'type', label: 'Группа', sortable: true },
      { key: 'profit', label: 'Вознаграждение', sortable: true },
      { key: 'updatedUser', label: 'Изменил', sortable: false },
      { key: 'updatedAt', label: 'Дата изменения', sortable: false },
    ]

    const {
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refActionProductTable,

      refetchData,
      clearSelectTable,
      selectRowTable,

      selectRules,
      updateElement,
      deleteElement,
      actionId,
    } = actionProductList()
    // закидываем в товары идентификатор акции
    actionId.value = props.actionId || null

    return {
      tableColumns,
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refActionProductTable,

      refetchData,
      clearSelectTable,
      selectRowTable,

      selectRules,
      formatDate,
      updateElement,
      deleteElement,

      ability,
    }
  },
}
</script>
