<template>
  <b-form>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          no-body
          class="border mb-0"
        >
          <b-card-header class="pb-1">
            <b-card-title>
              <feather-icon
                icon="Edit2Icon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-sm-inline">Основное</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-left justify-content-left mt-1 pb-50">
                  <b-form-checkbox
                    id="priceSwitch"
                    v-model="elementData.active"
                    :value="1"
                    :unchecked-value="0"
                    :disabled="!ability.can('update', 'ActionManage')"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="EyeIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="EyeOffIcon" />
                    </span>

                  </b-form-checkbox>
                  Активность
                </div>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Начало акции"
                  label-for="input-activeFrom"
                >
                  <flat-pickr
                    id="input-activeFrom"
                    v-model="elementData.activeFrom"
                    class="form-control"
                    placeholder="выберите начало акции"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Конец акции"
                  label-for="input-activeTo"
                >
                  <flat-pickr
                    id="input-activeTo"
                    v-model="elementData.activeTo"
                    class="form-control"
                    placeholder="выберите окончание акции"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Внешний код"
                  label-for="input-xmlId"
                >
                  <b-form-input
                    id="input-xmlId"
                    v-model="elementData.xmlId"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Сортировка"
                  label-for="input-sort"
                >
                  <b-form-input
                    id="input-sort"
                    v-model="elementData.sort"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Наименование"
                  label-for="input-title"
                >
                  <b-form-input
                    id="input-title"
                    v-model="elementData.title"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Псевдоним"
                  label-for="input-alias"
                >
                  <b-form-input
                    id="input-alias"
                    v-model="elementData.alias"
                    :disabled="!ability.can('manage', 'all')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="">
                <b-form-group
                  label="Цвет банера"
                  label-for="select-colorId"
                >

                  <v-select
                    id="select-colorId"
                    v-model="elementData.colorId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="выберите цвет банера"
                    :reduce="list => list.id"
                    :options="colorOption"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Иконка акции"
                  label-for="select-svgSrcId"
                >

                  <v-select
                    id="select-svgSrcId"
                    v-model="elementData.svgSrcId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="выберите иконку"
                    :reduce="list => list.id"
                    :options="svgSrcIdOption"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Описание акции"
                  label-for="input-description"
                >
                  <quill-editor
                    id="input-description"
                    v-model="elementData.description"
                    :options="snowOption"
                    :disabled="!ability.can('update', 'ActionManage')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >

        <b-row>
          <b-col>
            <b-card
              no-body
              class="border mb-2"
            >
              <b-card-header class="pb-1">
                <b-card-title>
                  <feather-icon
                    icon="ApertureIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-sm-inline">Тип акции</span>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Тип акции"
                      label-for="select-type"
                    >

                      <v-select
                        id="select-type"
                        v-model="elementData.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        placeholder="выберите тип акции"
                        :reduce="list => list.value"
                        :options="typeOption"
                        :disabled="!ability.can('update', 'ActionManage')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="elementData.type ==='quantity'"
                    cols="12"
                  >
                    <b-form-group
                      label="Количество единиц"
                      label-for="input-step"
                    >
                      <b-form-input
                        id="input-step"
                        v-model="elementData.step"
                        :disabled="!ability.can('update', 'ActionManage')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="elementData.type ==='quantity'"
                    cols="12"
                  >
                    <b-form-group
                      label="Вознаграждение за шаг"
                      label-for="input-profit"
                    >
                      <b-form-input
                        id="input-profit"
                        v-model="elementData.profit"
                        :disabled="!ability.can('update', 'ActionManage')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card-actions
              title="Информация"
              title-icon="InfoIcon"
              action-collapse
              collapsed
              no-body
              class="border mb-1"
            >
              <b-card-body>
                <b-list-group>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">

                    <h5 class="mb-0">
                      Товары
                    </h5>
                    <b-badge
                      variant="primary"
                      pill
                      class="badge-round"
                    >
                      {{ elementData.showCounter }}
                    </b-badge>
                  </b-list-group-item>
                  <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5>
                        Создал
                      </h5>
                    </div>
                    <b-card-text>
                      {{ elementData.createdUser }}
                    </b-card-text>
                  </b-list-group-item>
                  <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5>
                        Дата создания
                      </h5>
                    </div>
                    <b-card-text>
                      <span v-if="elementData.createdAt">{{ formatDate(elementData.createdAt) }} </span>
                      <span v-else>нет</span>
                    </b-card-text>
                  </b-list-group-item>
                  <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5>
                        Изменил
                      </h5>
                    </div>
                    <b-card-text>
                      {{ elementData.updatedUser }}
                    </b-card-text>
                  </b-list-group-item>
                  <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <h5>
                        Дата изменения
                      </h5>
                    </div>
                    <b-card-text>
                      <span v-if="elementData.updatedAt">{{ formatDate(elementData.updatedAt) }} </span>
                      <span v-else>нет</span>
                    </b-card-text>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-actions>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardText, BForm, BRow, BCol, BListGroup, BListGroupItem, BBadge, BFormCheckbox, BFormGroup, BFormInput, // BFormGroup, BFormInput, BCardText, BFormCheckbox, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import ability from '@/libs/acl/ability'
import { formatDate } from '@/libs/helper'
import BCardActions from '@/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormCheckbox,
    BFormGroup,
    BFormInput,

    flatPickr,
    vSelect,
    quillEditor,

    BCardActions,
  },
  props: {
    elementData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const typeOption = [
      { title: 'Бонусная', value: 'bonus' },
      { title: 'Количественная', value: 'quantity' },
    ]
    const snowOption = {
      theme: 'snow',
    }

    const colorOption = [
      { title: 'Цвет 1', id: 1 },
    ]
    const svgSrcIdOption = [
      { title: 'Иконка 1', id: 1 },
    ]
    return {
      typeOption,
      snowOption,
      colorOption,
      svgSrcIdOption,
      formatDate,
      ability,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
