<template>
  <div>
    <b-alert
      v-if="errorData"
      variant="danger"
      :show="!elementData"
    >
      <h4 class="alert-heading">
        Ошибка при извлечении данных
      </h4>
      <div class="alert-body">
        {{ errorData }} Проверьте
        <b-link
          class="alert-link"
          :to="{ name: 'moderation-bonuses'}"
        >
          лист модерации
        </b-link>
        для других пользователей.
      </div>
    </b-alert>

    <!-- <action-content-card
      :disabled-button-save="!ability.can('update', 'ActionManage')"
      :element-data="elementData"
      @close-actions="close()"
      @save-actions="save()"
    /> -->

    <b-card
      v-else
      :key="actionId"
      no-body
    >
      <b-card-header>
        <div class="d-flex align-items-center">
          <feather-icon
            size="24"
            class="mr-1"
            icon="EditIcon"
          />
          <b-card-title
            v-if="actionId === 'new'"
            class="ml-25"
          >
            Создание новой акция
          </b-card-title>
          <b-card-title
            v-else
            class="ml-25"
          >
            <span v-if="(elementData && elementData.id)">{{ elementData.title }} - </span>
            <span v-else>Новая акция</span>

            <span v-if="(elementData && elementData.id) && ability.can('update', 'ActionManage')">изменение</span>
            <span v-if="(elementData && elementData.id) && !ability.can('update', 'ActionManage')">просмотр</span>
          </b-card-title>
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="(tabs === 1 || !ability.can('update', 'ActionManage'))"
            @click="save()"
          >
            <span class="text-nowrap">Сохранить</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="ml-1"
            variant="outline-primary"
            @click="close()"
          >
            <span class="text-nowrap">Закрыть</span>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-tabs v-model="tabs">
          <b-tab active>
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Основное</span>
            </template>
            <action-view-admin-form
              :element-data="elementData"
            />
            <!-- <content-help-view-tab-general
              :content-data="elementData"
              class="mt-2 pt-75"
            /> -->
          </b-tab>
          <b-tab :disabled="(!actionId || actionId == 'new')">
            <template #title>
              <feather-icon
                icon="ShoppingBagIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Товары акции</span>
              <!-- <span class="d-none d-sm-inline"> ({{ (elementData.faq || []).length }})</span> -->
            </template>
            <action-product-table
              :action-id="actionId && actionId !== 'new' ? parseInt(actionId) : null"
            />
            <!-- <content-help-view-tab-faq
              :element-list="elementData.faq"
              class="mt-2 pt-75"
              @refetch-data="getElement()"
            /> -->
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BAlert, BLink, BTab, BTabs, BCard, BCardHeader, BCardBody, BCardTitle, BButton,
} from 'bootstrap-vue'
import ability from '@/libs/acl/ability'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ActionProductTable from '@/components/action-product/ActionProductTable.vue'
import actionView from './actionViewAdmin'
import ActionViewAdminForm from './ActionViewAdminForm.vue'
// import ActionContentCard from './ActionContentCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
    // ActionContentCard,
    ActionViewAdminForm,
    ActionProductTable,
  },
  directives: {
    Ripple,
  },
  methods: {
    async save() {
      try {
        if (!this.ability.can('update', 'ActionManage')) {
          throw new Error('Недостаточно прав для изменения')
        }
        await this.updateElement(1)
        await this.getElement(1)
        this.$swal({
          icon: 'success',
          title: 'Данные успешно сохранены!',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error ? `${error}`.replace('Error: ', '') : '',
          },
        })
      }
    },
    close() {
      this.$router.go(-1)
    },
  },
  setup() {
    const actionId = ref(router.currentRoute.params.id)
    const tabs = ref(0)
    const {
      elementData,
      errorData,

      getElement,
      resetBlankData,
    } = actionView()

    if (actionId.value !== 'new') {
      getElement(actionId.value).then()
    } else {
      resetBlankData()
    }
    return {
      actionId,
      tabs,
      elementData,
      errorData,
      ability,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
